<template>
	<div class='contain'>
		<img src="@/assets/imgs/com-auth-bg.png" alt="">
		<div class="main">
			<div class="flex_c">
				<p class="el-icon-warning mr_15"></p>
				<div v-if="company.status == 2">
					<p class="fs_20 color_333 bold">信息审核未通过</p>
					<!-- <p class="fs_12 color_666 mt_5">信息更正后可重新提交信息审核</p> -->
				</div>
				<div v-else>
					<p class="fs_20 color_333 bold">信息审核中</p>
					<p class="fs_12 color_666 mt_5">我们会尽快审核，请耐心等待！</p>
				</div>
			</div>
			<div class="fs_14 color_666" style="margin-top:35px;">
				<div class="flex_c">
					<img :src="company.logo" class="logo" alt="LOGO">
					<p class="color_333 bold">企业全称：{{ company.company_name }}</p>
				</div>
				<p class="mt_15">信用代码：{{ company.creditcode }}</p>
				<!-- <p class="mt_5">所属行业：缺字段</p> -->
				<p class="mt_5">公司规模：{{ company.staff_num_range }}</p>
				<p class="mt_10" style="color: #d80000;" v-if="company.status == 2">拒绝原因：{{ company.refusal_reason }}</p>
			</div>
            <div v-if="company.status == 2">
                <el-button type="info" class="fs_18" @click="toIndex">返回首页</el-button>
                <el-button type="primary" class="fs_18" @click="rePost">重新提交</el-button>
            </div>
            <div v-else>
                <el-button type="primary" class="fs_18" @click="toIndex">体验看看</el-button>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'inReview',
	data() {
		return {
			company: {}
		}
	},
	created() {
		this.getStatus()
	},
	methods: {
		getStatus() {
			this.$axios({
				url: '/ent/v3/usercenter/company/apply_status',
				method: 'GET'
			}).then(res=>{
				if(res.code === 200) {
					this.company = res.data
					if(res.data.status == 1) {
						this.$router.replace('/')
					}
				}else {
					this.$router.replace('/')
				}
			})
		},
		toIndex() {
			this.$router.replace('/index')
		},
		rePost() {
			this.$router.replace('/create')
		}
	}
}
</script>

<style lang='less' scoped>
.main {
    padding: 50px 100px 100px;
    background-color: #fff;
    .logo {
        width: 40px;
        height: auto;
        border-radius: 2px;
        margin-right: 10px;
    }
    .el-icon-warning {
        font-size: 54px;
        color: #FF9900;
    }
    .el-button {
        width: 200px;
        height: 52px;
        margin-top: 60px;
    }
}
</style>